import { useContext, createContext, useState } from 'react';
import React from 'react';
import { Breadcrumb, Layout, Menu, theme, message, Spin, Button } from 'antd';
import Login from './Login';
import Register from './Register';
import Analyse from './Analyse';
import LoginLogoutButton from './LoginLogoutButton';


import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Routes,
  Link,
  BrowserRouter,
  useNavigate,
  json,
  Navigate
} from "react-router-dom";

const { Header, Content, Footer } = Layout;
const items = new Array(5).fill(null).map((_, index) => ({
  key: index + 1,
  label: `nav ${index + 1}`,
}));
const LoginContext = createContext(null);
const App = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const [spinning, setSpinning] = useState(false);

  const success = (message) => {
    messageApi.open({
      type: 'success',
      content: message,
    });
  };

  const error = (message) => {
    messageApi.open({
      type: 'error',
      content: message,
    });
  };
  const warning = (message) => {
    messageApi.open({
      type: 'warning',
      content: message,
    });
  };

  const [user_data, set_user_data] = useState(JSON.parse(localStorage.getItem('user_data')));
  const navigate = useNavigate();

  const onRefRequest = (ref_type) => (amount, caderneta_id, phone_number) => async (ev) => {

    const result = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/get_ref`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          ref_type: ref_type,
          amount: amount,
          caderneta_id: caderneta_id,
          phone_number: phone_number
        })
      }
    )

    const result_json = await result.json()

  }
  const onLogout = () => {
    localStorage.removeItem("user_data")
    navigate("/login")

  }
  const onLogin = async (values) => {
    try {
      setSpinning(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(values), // body data type must match "Content-Type" header
      })
      setSpinning(false);
      let json_result = await response.json(); // parses JSON response into native JavaScript objects
      if (response.ok) {
        const user_data = {
          "jwt_token": json_result.access_token,
          "username": values.username
        }
        success("Login Successfull");
        set_user_data(user_data)
        localStorage.setItem('user_data', JSON.stringify(user_data))
        navigate("/analyse")
      }
      else {
        error(json_result.msg)
      }
    } catch (er) {
      setSpinning(false);
      error("Unknown Error Logging in")
    }

  };
  const onRegister = async (values) => {
    try {
      setSpinning(true);
      const result = await fetch(`${process.env.REACT_APP_BACKEND_URL}/register`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(values) // body data type must match "Content-Type" header
      });
      setSpinning(false);
      const result_json = await result.json()
      if (!result.ok) {
        error(result_json.msg)
      }
      navigate("/login")
    } catch (err) {
      setSpinning(false);
      error("Unknown Error Registering")
    }

  };
  const onAnalyseRequest = async (values) => {

    let run_nr = values.process_nr
    let run_result = []
    if (values.files) {
      const result = await upload(values.files);
      for (const run_id of result.result.run_ids) {
        const run_result_check = await check_run(run_id);
        run_result.push(run_result_check[0]);

      }
    }
    else {
      run_result = await get_data_for_process(run_nr)
    }

    return run_result;

  }
  const upload = async (files) => {
    try {
      setSpinning(true);
      var data = new FormData()
      //data.append('caderneta', values.files[0].originFileObj);

      for (const file of files.fileList) {
        data.append(file.name, file.originFileObj, file.name);
      }
      const result = await fetch(`${process.env.REACT_APP_BACKEND_URL}/upload?source=website`, {
        mode: "cors",
        headers: {
          "Authorization": `Bearer ${user_data.jwt_token}`,
          'Access-Control-Allow-Origin': '*'
        },
        method: 'POST',
        body: data
      })
      setSpinning(false);
      const result_json = await result.json()
      if (!result.ok) {
        error(result_json.msg)
      }
      else {
        success("Upload bem sucedido!")
        return result_json
      }

    } catch (er) {
      error("Unknown error");
      setSpinning(false);
    }
  }
  const get_data_for_process = async (process_nr) => {
    setSpinning(true);
    try {
      const result = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/get_data_for_process?process_nr=${process_nr}`,
        {
          headers: {
            "Authorization": `Bearer ${user_data.jwt_token}`
          },
          method: "GET"
        })
      setSpinning(false);
      const result_json = await result.json();
      if (!result.ok) {
        error("Unable to get data for process");
        return []
      }
      return result_json.result

    } catch (er) {
      setSpinning(false)
      error("Unknown error")
    }

  }
  const check_run = async (run) => {
    const result = await fetch(`${process.env.REACT_APP_BACKEND_URL}/run`, {
      headers: {
        "Authorization": `Bearer ${user_data.jwt_token}`,
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        "run": run ? run : ""
      })
    })
    const result_json = await result.json();
    if (!result.ok) {
      error(result_json.msg);
      return []
    }
    return result_json.result.map(x => x.extracted_data).flat()
  }

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const logged_in = user_data != undefined;
  console.log(`Status: Logged_in = ${logged_in} (${user_data})`)
  return (
    <Layout>
      <Spin spinning={spinning} fullscreen />
      {contextHolder}
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          color: 'white'
        }}
      >
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
          items={items}
          style={{
            flex: 1,
            minWidth: 0,
          }}
        />
        <LoginLogoutButton navigate={navigate} onLogout={onLogout} />
      </Header>
      <Content
        style={{
          padding: '0 48px',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            background: colorBgContainer,
            minHeight: 280,
            padding: 24,
            borderRadius: borderRadiusLG,
          }}
        >
          <Routes>
            <Route path="/" element={<Navigate to={logged_in ? "/analyse" : "/login"} replace />} />
            <Route path='/analyse' element={<Analyse onFinish={onAnalyseRequest} {...{ message, error, warning }} />} />
            <Route path='/login' element={<Login onFinish={onLogin} />} />
            <Route path='/register' element={<Register onFinish={onRegister} />} />
          </Routes>

        </div>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        MEB Fiscal ©{new Date().getFullYear()} Created by MEB Fiscal
      </Footer>
    </Layout>
  );
};
export default App;