import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'antd';

const Header = ({ onLogout, navigate }) => {
    const location = useLocation();
    const [info, setInfo] = useState({ title: "Login", is_logged: window.localStorage.getItem("user_data") !== undefined })
    useEffect(() => {
        // Update the state based on the current path
        if (info.is_logged) {
            setInfo({ ...info, title: "Logout" })
        } else {
            setInfo({ ...info, title: "Login" })
        }
    }, [location]);  // React to changes in location

    return location.pathname === "/login" ? <></> : <Button type={info.is_logged ? "default" : "primary"} onClick={async () => {

        if (info.is_logged) {
            onLogout()
        }
        else {
            navigate("/login")
        }


    }}>{info.is_logged ? "Logout" : "Login"}</Button>

};

export default Header;