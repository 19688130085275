import { Button, Checkbox, Form, Input } from 'antd';
import { LockOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';
import {Link} from 'react-router-dom';

const Register = ({onFinish}) => {
    
    //const [form] = Form.useForm();
    return (<Form
        //form={form}
        name="normal_login"
        className="login-form"
        initialValues={{
            remember: true,
        }}
        onFinish={onFinish}
    >
        <Form.Item
            name="username"
            rules={[
                {
                    required: true,
                    message: 'Por favor introduzir um Username!',
                },
            ]}
        >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
        </Form.Item>
        <Form.Item
            name="email_address"
            rules={[
                {
                    type: "email",
                    required: true,
                    message: 'Por favor introduzir um email!',
                },
            ]}
        >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>
        <Form.Item
            name="password"
            rules={[
                {
                    required: true,
                    message: 'Por favor inserir uma password!',
                },
            ]}
        >
            <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
            />
        </Form.Item>
         <Form.Item
        name="confirm"
        label="Confirmar Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Por favor confirme a sua password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('As passwords não são iguais!'));
            },
          }),
        ]}
      >
        <Input.Password placeholder='Confirmar Password'/>
      </Form.Item>


        <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" >
                Register
            </Button>
            Or <Link to="/login">Login now!</Link>
        </Form.Item>
    </Form>)
}
export default Register;