import { Button, Form, Input, Upload, Table, Select } from 'antd';
import { NumberOutlined, InboxOutlined, DownOutlined } from '@ant-design/icons';
import { useState } from 'react'

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

const ColNameMap = {
    "A": "A",
    "Ca": "Ca",
    "Cl": "Cl",
    "Cq": "Cq",
    "Cv": "Cv",
    "Vc": "Vc",
    "Vt": "Vt",
    "afectacao": "Afectação",
    "andar": "Andar",
    "andar/divisão": "Andar/Divisão",
    "andar_data": "Andar Data",
    "ano de inscrição na matriz": "Ano Inscrição",
    "area bruta de construcao": "Área Bruta Const.",
    "area bruta dependente": "Área Bruta Dep.",
    "area bruta privativa": "Área Bruta Priv.",
    "area de implantação do edificio": "Área de Implatação",
    "area total do terreno": "Área total Terreno",
    "area do terreno integrante": "Área Terreno Integrante",
    "area de terreno integrante das fracções": "Área Terreno Integrante Fracções",
    "area bruta privativa total": "Área Bruta Priv. Total",
    "artigo matricial": "Artigo",
    "augmentable_by": "Augment Factor",
    "augmented": "Augmented",
    "av./rua/praça": "Morada",
    "avaliada em": "Avaliada a",
    "charge": "Charge",
    "cod": "Cod",
    "codigo postal": "CP",
    "coef_json": "JSON Coefs",
    "concelho": "Concelho",
    "coords": "Coordenadas",
    "decrease": "Poupança",
    "determinado no ano": "Data Determinação VPT",
    "distrito": "Distrito",
    "entregue em": "Data Entrega Mod 1",
    "ficha de avaliação n": "# Ficha",
    "freguesia": "Freguesia",
    "id": "ID",
    "identifier": "Proc #",
    "infer_cv": "Cv Inferido",
    "lat": "Latitude",
    "lon": "Longitude",
    "localidade": "Localidade",
    "lugar": "Lugar",
    "mod 1 do imi": "Mod 1",
    "n de pisos da fracção": "N Pisos Frac.",
    "n de pisos do artigo": "N Pisos Art.",
    "new_cl": "Novo Cl",
    "new_cv": "Novo Cv",
    "nif_1": "NIF",
    "nome_1": "Titular",
    "nif_2": "NIF 2",
    "nome_2": "Titular 2",
    "fracção autonoma": "Frac. Autónoma",
    "permilagem": "Permilagem",
    "origin": "Origin",
    "picked_coef": "Coef Escolhido",
    "price": "Preço",
    "serviço finanças": "Serviço Financas",
    "taxa": "Taxa Aplicada",
    "tipo": "Tipo",
    "tipo de coeficiente de localizacao": "Tipo Cl",
    "tipo de predio": "Tipo de Prédio",
    "tipologia/divisões": "Tipologia",
    "tmp_file_url": "Ficheiro",
    "valor patrimonial actual": "VPT Atual",
    "vp_atual": "VPT Atual",
    "vpa": "VPT Inicial",
    "x": "X",
    "y": "Y"
}

const defaultCheckedList = ["Cl", "Cv", "Vc", "Vt", "ano de inscrição na matriz", "decrease", "determinado no ano", "identifier", "new_cl", "new_cv", "taxa", "vpa"]
const Analyse = ({ onFinish, message, error, warning }) => {

    const [form] = Form.useForm();
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const [submit_disabled, set_submit_disabled] = useState(true)
    const [table_data, set_table_data] = useState({ data_source: [], columns: [] })

    // const handleRefQuest = async (type) => (ev) => {
    //     fetch(`${process.env[]}`)
    // }
    const handleSubmit = async (form) => {

        try {

            const vals = form.getFieldsValue();
            const result = await onFinish(vals)
            const keys = Object.keys(result[0]).filter(k => (typeof result[0][k]) != 'object')
            set_table_data({
                data_source: result.map(x => (keys.reduce((obj, key) => { obj[key] = x[key]; return obj }, {}))),
                columns: keys.map((k) => {
                    let extra_keys = {}
                    if (k == "tmp_file_url"){
                        extra_keys["render"] =(text, record) => (
                            <a href={text} target="_blank" >
                                Caderneta
                            </a>)
                    }
                    return { title: ColNameMap[k], dataIndex: k, key: k, ...extra_keys }
                })
            })
        } catch (er) {
            error("Error Loading data onto table")
        }


    }
    const fields_changed = (ev) => {
        const vals = form.getFieldsValue();
        return set_submit_disabled(Object.keys(vals).filter(k => vals[k] != undefined && vals[k] != '').length == 0)
    }

    const options = table_data.columns.map(({ key, title }) => ({
        label: title,
        value: key,
    }));
    
    const new_columns = table_data.columns.map((item) => ({
        ...item,
        hidden: !checkedList.includes(item.key),
    }));

    const suffix = (
        <>
            <span>
                {checkedList.length} cols
            </span>
            <DownOutlined />
        </>
    );
    return (<>
        <Form
            form={form}
            onFieldsChange={fields_changed}
            name="normal_login"
            className="login-form"
            initialValues={{
                remember: true,
            }}
        >
            <Form.Item
                name="process_nr"
                label="Insira o Nr. de processo"
            >
                <Input prefix={<NumberOutlined className="site-form-item-icon" />} placeholder="Nr. Processo" />
            </Form.Item>
            Ou
            <Form.Item label="Caderneta">
                <Form.Item name="files" valuePropName="fileList2" noStyle>
                    <Upload.Dragger name="files" customRequest={dummyRequest} multiple={true}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Carregue aqui ou arraste para cá uma caderneta</p>
                        <p className="ant-upload-hint">Suporter para um ou vários ficheiros</p>
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button" onClick={() => handleSubmit(form)}>
                   Analyse 
                </Button>
            </Form.Item>
            {/* <Form.Item noStyle shouldUpdate>
                {() => (
                    <Typography>
                        <pre>{JSON.stringify(result, null, 2)}</pre>
                    </Typography>
                )}
            </Form.Item> */}
        </Form>
        <Select
            mode="multiple"
            value={checkedList}
            style={{
                width: '100%',
                marginBottom: '2%'
            }}
            onChange={setCheckedList}
            suffixIcon={suffix}
            placeholder="Select Columns"
            options={options}
            filterOption={(input, option) => {
                if (option.label === undefined){
                    return true;
                }
                return option.label.toLowerCase().indexOf(input.toLowerCase()) !== -1;
                //return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
        />

        <Table dataSource={table_data.data_source} columns={new_columns} scroll={{ x: "100%", y: 300 }} />

        <Button>Gerar Referência</Button>
        <Button>Gerar MBWay</Button>
    </>
    )
};

export default Analyse;